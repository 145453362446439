.box{
    color: #ff0000;
    padding: 8px 10px 0px 10px;
    border: 1px solid #f55858;
    /* background-color: #fff7f7; */
}

.marginTop{
    margin-top: 15px;
}

.header{
    font-size: 0.98rem;
    font-weight: bold;
}

.box.smallHeader .header{
    font-size: 0.9rem;
}