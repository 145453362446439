.header{
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    font-family: sans-serif;
    margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
    .headerResponsive {
      text-align: left;
    }
  }