.wrapper{
    /* display: flex;
    align-items: center;
    flex-direction: column; */

    text-align: center;

    background-color: #efefef;
    border: 1px solid #d9d9d9;
    padding: 13px 10px 13px 10px;

    font-size: 1.03rem;

    height: 100%;
}

.wrapper:hover{
    cursor: pointer;
    color: rgb(35, 112, 255);
}

.type{
    display: block;
}

.downloadIcon{
    display: none;
    font-size: 3.5rem;
}

.wrapper:hover .downloadIcon{
    display: block;
}

.wrapper:hover .iconWrapper{
    display: none;
}

.iconBlock{
    margin: 15px 0 15px;
}

.size{
    font-size: 0.97rem;
}

.date{
    display: block;
    margin: 10px 0 10px;
    font-weight: bold;
    /* text-decoration: underline; */
    font-size: 1.1rem;
}

.extension{
    font-style: italic;
    font-size: 0.93rem;
    opacity: 0.9;
}
