.logo{
    width: 160px;
    height: 48px;
    margin: 0 -100px 0 0;
}

@media (max-width: 991px) {
    .logo {
      margin-right: -10px;
    }
  }