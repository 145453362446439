.menu{
    align-content: flex-end;
    width: 40px;
    
}

.avatar{
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color: #4880c9;
    font-weight: bold;

}

.avatar:hover{
    cursor: pointer;
    background-color: #4179c9;
}


.userInfo {
    cursor: default;
    text-align: center;
}

.userInfo:hover{
    background-color: #fff;
}