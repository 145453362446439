

/* body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } */

  body{
    font-family: sans-serif;
    font-size: 16px;
    color: #000;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  
 .ant-input, .ant-input-affix-wrapper, .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker, .ant-checkbox-inner{
   border-color: #aaa;
 }

