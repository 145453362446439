

.chooser{
    width: 100%;
}


.chooser :global .ant-select-selection-item{
    /* font-weight: bold; */
    text-align: center;
    font-size: 1.00rem;
    font-style: italic;
    
}



.chooserWrapper{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.chooserWrapperInner{
    max-width: 600px;
    width: 100%;
}


/* .chooser :global .ant-select-selection-item:before{
    content: 'Customer: ';
    opacity: 0.8;
} */

@media (min-width: 763px) {
    .chooser :global .ant-select-selection-item:before{
        content: 'Customer: ';
        opacity: 0.8;
    }
  }