.drawer{

}

.drawer :global(.ant-drawer-content-wrapper){
    /* width: 100% */
    max-width: 310px;
}

.drawer :global(.ant-drawer-close){
    font-size: 30px;
    color: #0e56b4;
}

.drawer :global(.ant-drawer-close):hover{
    color: #196fdf;
}

.filler{
    margin-bottom: 40px;
}