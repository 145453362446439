.base{
    font-size: 3.5rem;
}

.csv{
    composes: base;
    color: #fc8803;
}

.pdf{
    composes: base;
    color: #f04134;
}

.excel{
    composes: base;
    color: #1F7244;
}

.general{
    composes: base;
}