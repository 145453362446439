.item{
    background-color: #288ecd;
	transition: background-color 0.3s cubic-bezier(0.045, 0.045, 0.055, 1); 
    /* width: 33%; */
    color: #fff;
    height: 220px;
    
    display: flex;
    align-items: baseline;
    align-content: center;
    flex-direction: column;
    justify-content: center;
}

.item:hover{
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #288ecd;
    color: #288ecd;
    font-style: italic;
}

.content{
    display: block;
    text-align: center;
    width: 100%;
}

.title{
    font-size: 1.5rem;
}

.icon{
    font-size: 80px;
}


