@import "./MenuDrawer.module.css";

.menu{
    color: inherit;
    transition: none;
}



.menu:not(:global .ant-menu-horizontal) :global .ant-menu-item-selected{
    background-color: #fff;
}

:global .ant-tooltip.ant-menu-inline-collapsed-tooltip a{
    color: #fff;
}

/* :global .ant-tooltip.ant-menu-inline-collapsed-tooltip a:hover{
    color: #8888ff;
} */

:global .ant-menu.ant-menu-inline-collapsed .ant-menu-item .anticon, :global .ant-menu.ant-menu-inline .ant-menu-item .anticon{
    font-size: 18px;
}

:global(.ant-menu-item:not(.ant-menu-item-selected)) {
    &.admin {
    color: #888;
}}

:global(.ant-menu-inline).menu{
    border-right: none;
}

