.type{
    border: 1px solid #666;
    /* display: inline-block; */
    padding: 0px 4px 0px 4px;
    margin: 6px 6px 0 0;
    background-color: #eee;
    color: #000;
    font-size: 0.8rem;

    white-space: nowrap;
}

.wrapper{
    max-width: 320px;
    /* width: 80%; */
    white-space: pre-wrap;
}