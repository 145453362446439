.table{
    color: red;
}

.table :global .ant-table{
    margin-top: 20px;
}
  
.table :global .ant-table :global .ant-table-thead th{
    font-weight: bold;
  }

/* .table :global .ant-table-tbody tr td {
} */

$border-color: rgb(150, 150, 150);

:global(.ant-table-wrapper).darkBorder {

    :global(.ant-table) , :global(.ant-table.ant-table-bordered) {

        :global(.ant-table-container){
            border-left-color: $border-color;
        }

        table{
            border-top-color: $border-color;
            border-left-color: $border-color;


            :global(td.ant-table-cell), :global(th.ant-table-cell){
                border-bottom-color: $border-color;
                border-right-color: $border-color;
                border-left-color: $border-color;
            }
        }
    }
}
