.info{
    font-style: italic;
    font-size: 0.87rem;
    color: #666;
    opacity: 1;
    padding: 0 5px 0 5px;
}

.marginBottom{
    margin-bottom: 15px;
}