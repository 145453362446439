.section{
    margin-bottom: 30px;   
}

.section h2{
    margin: 0 0 0 5px;
    font-weight: bold;
    font-size: 1.1rem;
}

.data{
    margin-top: 30px;
}

.notAvailable{
    color: #888;
    font-style: italic;
    margin: 10px 0 0 5px;
    
}