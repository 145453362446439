.division{
    font-style: italic;
  color: #999;
}

.number{
    font-weight: bold;

    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-decoration-color: #aaa;
}

