:global(.ant-table table).table{
    width: auto;
}
.row:not(:last-child) td{
    border-bottom: 1px solid #b6b6b6;
}

 .row td{
    padding: 4px 3px 4px 3px;
}

.row td {
    &:global(.action){
    padding-left: 15px;
}}

.row:hover{
    /* opacity: 0.8; */
    color: rgb(27, 92, 231);
}
