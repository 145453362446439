.section{
}

.section :global(.ant-divider-horizontal.ant-divider-with-text){

    border-top-color: inherit;

    &::before, &::after{
        border-top: 0;
        border-bottom: 1px solid #999;
        transform: translateY(-40%);
    }
}