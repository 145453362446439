.footer{
    max-width: 1200px;
    margin: auto;
}

.logoWrapper{
    text-align: right;
}

/* .velocityLink{
    font-size: 1.5rem;
    font-weight: bold;
} */